.toolbar {
  position: fixed;
  width: 100%;
  height: 56px;

}
.dots {
  display: flex;

}
.dot1 {
  height: 2px;
  width: 2px;
  border-radius: 50%;

  padding: .5rem;

  background-color: #EE5C54;

}
.dot2 {
  height: 2px;
  width: 2px;
  border-radius: 50%;

  padding: .5rem;

  background-color: #F8BD45;

}
.dot3 {
  height: 2px;
  width: 2px;
  border-radius: 50%;

  padding: .5rem;

  background-color: #5ECA42;

}

.icons {
  color: #4C4C4C;

  padding: 0 .5rem;
  display: inline-block;
}
.arrows{
  color: #6C6C6C;
  padding: 0 .5rem;
}
.button{

  padding: 0 0.5rem;

}
.searchbar {
  background-color: #F6F6F6;
  height: 30px;
  width: 300px;
  border-radius: 10%;
  text-align: center;
  color: #AAAAAA;

}

.toolbar__navigation {
  background-color: #DBDBDB;
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 1rem;

}

.toolbar__logo {
  margin-left: 1rem;
}
.toolbar__airplay{
  margin-left: 1rem;
  margin-right: 1rem;
}

.toolbar__logo a {
  color: #8E793E;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: bold;
}
.spacer {
  flex: 1;
  background-color: #ECECEC;
  height: 94%;

}
.screen {
  vertical-align: middle;
  align-items: center;
  align-content: center;
  color: #646464;
}


.toolbar__navigation-items ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-content: right;
}

.toolbar__navigation-items li {
  padding: 0 0.5rem;
}

.toolbar__navigation-items a {
  color: #231F20;
  text-decoration: none;
}

.toolbar__navigation-items a:hover,
.toolbar__navigation-items a:active {
  color: #8E793E;

}

@media (max-width: 768px) {
  .toolbar__navigation-items {
    display: none;
  }
}

@media (min-width: 769px) {
  .toolbar__toggle-button{
    display: none;
  }
}
