.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  padding: 4px;

}
.red {
  background-color: #EE5C54;

}
.yellow {
  background-color: #F8BD45;

}
.green {
  background-color: #5ECA42;
}
.maincontent{
  height: 100%;
  display: flex;
  width: 100%

}
.playlist {
width: 82%;


}
.sidenav {
  color: #575757;
  padding-top: 56px;
  width: 18%;

}
.temp{
  display: none;
  visibility: hidden;
}
