.songheaders {
  display: flex;
  padding-top: 56px;
  padding-left: 1rem;

}
.songsort {
  padding: 0 1rem;
  /* width: 205px; */
  width: 33%;
}
.equil {
  padding: 0 1rem;

}

.Songlist{
  height: 100vh;
  overflow-y: scroll;

}
